import { isEmpty } from 'lodash-es'

import type { Coverage } from '@/@types/Coverage'
import type Product from '@/@types/Product'
import { AccountSummaryItemHydrated } from '@/domain/Account/AccountSummaryItemHydrated'
import type { Policy, PolicyPet } from '@/domain/Policy'
import type { PolicyV2 } from '@/domain/PolicyV2'
import { getPolicyOriginalStartDate } from '@/legacy/lib/utils'
import dayjs, { DayJsParsable } from '@/lib/dayjs'
import { getOrdinal } from '@/lib/utils'
import { PolicySnapshot } from '@/lib/utils/policySnapshot'

interface LegacyPolicy {
  products?: Record<string, Product>
}
interface July2020ModelPolicy {
  data: {
    product?: string
  }
  coverages: Coverage[]
}

export type NonGlobalPolicy = LegacyPolicy | July2020ModelPolicy

/**
 * Return whether a given policy supports the lifecycles model
 *
 * @param {object} policy The policy to check
 * @returns {boolean} Indicates if the policy is a lifecycle policy or not
 */
export function isLifecyclePolicy(policy: Policy): boolean {
  return Object.keys(policy).includes('id')
}

export type PolicyTypes =
  | Policy
  | PolicyV2
  | PolicySnapshot
  | AccountSummaryItemHydrated

export const isAccountSummaryItemHydrated = (
  policy: PolicyTypes
): policy is AccountSummaryItemHydrated =>
  !!(policy as AccountSummaryItemHydrated).type

export const isPolicySnapshot = (
  policy: PolicyTypes
): policy is PolicySnapshot => !!(policy as PolicySnapshot)?.policyId

export const isPolicy = (policy: PolicyTypes): policy is Policy =>
  !!(policy as Policy)?.data

export const isPolicyV2 = (policy: PolicyTypes): policy is PolicyV2 =>
  !!(policy as PolicyV2)?.agency

/**
 * Return whether a given policy is of the July 2020 structure / model
 *
 * @param {object} policy The policy to check
 * @returns {boolean} Whether the policy is of the 'July 2020' model
 */
export function isJuly2020Model(
  policy: NonGlobalPolicy
): policy is July2020ModelPolicy {
  return !isEmpty((policy as July2020ModelPolicy).coverages)
}

/**
 * Determine the product name for a given policy.
 *
 * @param {object} policy The policy to interrogate for a product name.
 * @returns {string} The product name or empty string is one cannot be determined.
 */
export function productName(policy: NonGlobalPolicy): string {
  return isJuly2020Model(policy)
    ? (policy.data.product ?? '')
    : (policy.products?.[0]?.name ?? '')
}

/**
 * @param {Policy} policy The policy
 * @param petUuid The pet uuid
 * @returns {PolicyPet} the policy pet
 */
export function getPetFromPolicy(
  policy: Policy,
  petUuid: string
): Maybe<PolicyPet> {
  let pets: PolicyPet[] = []

  if (policy.data.insured_entities) {
    pets = policy.data.insured_entities
  } else if (policy.data.insured_entity) {
    pets = [policy.data.insured_entity]
  }

  return pets.find((pet) => pet.uuid === petUuid)
}

export const getPolicyYearOrdinalFromPolicyData = (
  {
    originalPolicyStartDate,
    status,
    cessationDate,
  }: { originalPolicyStartDate: string; status: string; cessationDate: string },
  asOfDate?: DayJsParsable
): string => {
  if (!dayjs(asOfDate).isSameOrAfter(originalPolicyStartDate)) {
    console.error(
      `Queried date ${asOfDate} is before the start date of the policy ${originalPolicyStartDate}`
    )
    return 'unknown'
  }

  if (['VOID', 'CANCELLED'].includes(status)) {
    // Subtracting one day ensures it doesn't display an additional year
    // on the Policy year ordinal.
    asOfDate = dayjs(cessationDate).subtract(1, 'day')
  }

  const policyTermIncrement = dayjs(asOfDate).diff(
    originalPolicyStartDate,
    'year'
  )

  // Add one to the year, as year diff will always round down.
  const policyTermCardinal = policyTermIncrement + 1

  return getOrdinal(policyTermCardinal)
}

/**
 * Return the policy year ordinal for a provided date in time
 *
 * @param {object} policy The policy object
 * @param {string} asOfDate Dayjs parsable string of the date to get the policy year for.
 *
 * @returns {string} The policy year ordinal (1st, 2nd, 3rd), or unknown if it could not be identified
 */
export function getPolicyYearOrdinalFromDate(
  policy: PolicyTypes,
  asOfDate?: DayJsParsable
): string {
  if (isPolicy(policy)) {
    let originalPolicyStartDate
    try {
      originalPolicyStartDate = getPolicyOriginalStartDate(policy)
    } catch (error) {
      // Start date could not be determined from policy data.
      console.error(error)
      return 'unknown'
    }

    return getPolicyYearOrdinalFromPolicyData(
      {
        originalPolicyStartDate,
        status: policy.status,
        cessationDate: policy.cessation_date,
      },
      asOfDate
    )
  } else if (isPolicyV2(policy)) {
    return getPolicyYearOrdinalFromPolicyData(
      {
        originalPolicyStartDate: policy.cover.first_policy_year_start_date,
        status: policy.status,
        cessationDate: policy.cover.policy_year_end_date,
      },
      asOfDate
    )
  }

  return 'unknown'
}

export const getPolicyV1Status = (policy: Policy): string => {
  if (
    policy.status === 'ON_RISK' &&
    policy.data?.policy?.inception_date &&
    dayjs.utc(policy.data.policy.inception_date).isAfter(dayjs.utc(), 'day')
  ) {
    return 'AWAITING_INCEPTION'
  }

  if (
    policy.status === 'CANCELLED' &&
    policy.metadata?.termination_cause === 'DO NOT USE - SGP Migrated'
  ) {
    return 'SGP_MIGRATED'
  }

  return policy.status
}

export const getPolicyCarrierName = (carrier: string): string => {
  const carrierNames: Record<string, string> = {
    glise_uk: 'Glise UK',
    glise_se: 'Glise SE',
    wakam_uk: 'Wakam UK',
    accredited_us: 'Accredited US',
    mp_us: 'MP US',
  }

  return carrierNames[carrier] ?? carrier
}

import { AgencyConfig } from '@/@types/Config'
import deepFreeze from '@/lib/utils/deepFreeze'

const agencyConfig: AgencyConfig = {
  flags: {
    allowCreatingNewClaim: false,
    showSsn: true,
    showCalculationsList: false,
    customerNotesEnabled: false,
    allowEditClaimTitle: false,
    allowAddLossRecoveredAmount: false,
    allowDocumentRequestFromVet: false,
    allowDifferentPostcodes: false,
    blockQuoting: true,
    dobAsRatingFactor: false,
    enableNewFlow: false, // will be used to force SGP enable for staging
    forceDisableNewFlow: true, // we never want to enable the new flow for SE
    enableEmailOrder: false,
    nudgeForNewFlow: false,
    disableOldQuotingFlow: false,
    displayCancellationWarningText: false,
    displayCustomerFlagsDropdown: true,
    displayFullAddressOnNewBusiness: false,
    allowEditLegacyCustomer: true,
    disableEditEffectiveDate: false,
    disableEditInceptionDate: true,
    enableNewBusinessNextDayInceptionDate: false,
    enableEndOfBillingPlusOneCancellation: false,
    enableDirectDebit: false,
    enableNewDocumentsTab: true,
    removeSubscriptionsTab: false,
    displayWaitingPeriod: false,
    enableCommunication: false,
    enableUserHistory: false,
    enableRetainedPremiumsCalculations: false,
    enableReinstatement: false,
    enableSendingAffiliateCode: false,
    enableResetSmsMfa: false,
    enableNewRenewalUi: false,
    newFinancialTabIsEnabled: false,
    enableCerfScore: false,
    ibaPetTimelineEnabled: false,
    enableNewBusinessEditPaymentDate: false,
    enableCurrentBusinessEditPaymentDate: false,
  },
  currency: {
    code: 'SEK',
    symbol: 'kr',
    symbolPosition: 'append',
  },
  copaymentType: 'co-payment',
  excessType: 'excess',
  locale: 'sv-SE',
  dateFormatForLocale: 'D MMM YYYY',
  algolia: {
    breedLookup: {
      id: 'Z4FVQ1K5IA',
      index: 'pets',
      key: '7e7ee79d26fbc162ecbfdbe3577ea7f2',
    },
  },
  globalProductLines: ['katt-hund-pio'],
  ssnLookupUrl: 'https://se-prod-api.manypets.com/-/ssn/',
  paymentTypes: ['SwedenAccount'],
  customerCountryIsRequired: true,
  customerStateIsRequired: false,
  updatePetStateOnCustomerEdit: false,
  quotableProductLines: ['katt-hund'],
  legacyProductLines: ['katt-hund'],
  sgpProductLines: [],
  forceNoTimezone: true,
  blockMtasInDaysBeforeCessationDate: 31,
  usePcaStateCode: false,
  maxPetAge: 21,
}

export default deepFreeze(agencyConfig)
